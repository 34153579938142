import { ApolloError } from '@apollo/client'

const MIME_TYPE = 'text/csv'

export const isValidFileType = (fl: FileList): boolean => {
  if (fl?.length === 0) {
    return true
  }
  return fl?.[0]?.type === MIME_TYPE
}

export const getErrorMessage = (e: ApolloError) => {
  const errorDetails = e?.graphQLErrors?.[0]?.extensions
  if (errorDetails?.code?.startsWith('csv.row')) {
    const rowNumber: number = errorDetails.details.rowIndex + 1
    return `Error in row ${rowNumber}: ${e.message}`
  } else {
    return e?.message ?? 'Cannot create devices'
  }
}
