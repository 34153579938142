import { HiOutlineCheckCircle } from 'react-icons/hi'

import { ModalFooter } from '@chakra-ui/react'

import { Button } from '@/components/ui'

interface IncidentModalFooterIProps {
  disabled: boolean
  isLoading: boolean
  onClose: () => void
  onClick: () => void
}

export const IncidentModalFooter = ({
  disabled,
  isLoading,
  onClose,
  onClick,
}: IncidentModalFooterIProps) => {
  return (
    <ModalFooter py={4}>
      <Button
        data-testid='createIncidentModal_cancel'
        mr={3}
        onClick={onClose}
        variant='secondary'
      >
        Cancel
      </Button>
      <Button
        data-testid='createIncidentModal_createIncident'
        isDisabled={disabled}
        isLoading={isLoading}
        leftIcon={<HiOutlineCheckCircle size='24px' />}
        loadingText='Creating Incident'
        onClick={onClick}
      >
        Create Incident
      </Button>
    </ModalFooter>
  )
}
