import { Grid, GridItem, Text } from '@chakra-ui/react'

import { GetComponentStatusChangeQuery } from '@/graphql/appsync-component-health'
import { formatDateTime } from '@/utils/formatDateTime'

import { ComponentHealthStatusBadge } from './ComponentHealthStatusBadge'
import { GatewayComponentStatusSkeleton } from './GatewayComponentStatusSkeleton'

interface GatewayComponentStatusIProps {
  componentStatusData?: GetComponentStatusChangeQuery
  hasError?: boolean
  loading: boolean
}

export const GatewayComponentStatus = ({
  componentStatusData,
  hasError,
  loading,
}: GatewayComponentStatusIProps) => {
  if (loading) {
    return <GatewayComponentStatusSkeleton />
  }

  if (!componentStatusData && hasError) {
    return (
      <Text color='red' fontSize='14px'>
        Cannot retrieve component health status
      </Text>
    )
  }

  const componentName =
    componentStatusData?.getComponentStatusChange?.componentName
  const lastSeen = componentStatusData?.getComponentStatusChange?.lastSeen
  const status =
    componentStatusData?.getComponentStatusChange?.componentHealthStatus

  return (
    <Grid
      alignContent='center'
      alignItems='center'
      color='#2D2E41'
      fontSize='14px'
      gap='5px 2px'
      templateColumns='1fr 160px 90px'
      textAlign='left'
    >
      <GridItem>
        <Text fontWeight='semibold'>Component</Text>
      </GridItem>
      <GridItem>
        <Text fontWeight='semibold'>Last Seen</Text>
      </GridItem>
      <GridItem>
        <Text fontWeight='semibold'>Status</Text>
      </GridItem>
      <GridItem>
        <Text>{componentName}</Text>
      </GridItem>
      <GridItem>{formatDateTime(lastSeen)}</GridItem>
      <GridItem>
        <ComponentHealthStatusBadge status={status} />
      </GridItem>
    </Grid>
  )
}
