import { Badge } from '@chakra-ui/react'

import { ComponentHealthStatus } from '@/graphql/appsync-component-health'

export const ComponentHealthStatusBadge = ({
  status,
}: {
  status?: ComponentHealthStatus
}) => {
  return (
    <Badge
      bgColor={getComponentHealthStatusColor(status)}
      color='#fff'
      data-testid='incidentsPage_table_statusCell'
      px='2'
      rounded='md'
      textTransform='capitalize'
    >
      {status}
    </Badge>
  )
}

const getComponentHealthStatusColor = (status: ComponentHealthStatus) => {
  switch (status) {
    case ComponentHealthStatus.RUNNING:
      return 'incident.resolved.100'
    case (ComponentHealthStatus.BROKEN, ComponentHealthStatus.ERRORED):
      return 'incident.active.100'
    case ComponentHealthStatus.FINISHED:
      return 'incident.progress.100'
    default:
      return '#888888'
  }
}
