import { useEffect, useState } from 'react'

import { useExternalAccountsQuery } from '@/graphql/generated/hooks'
import { StreamingType } from '@/graphql/generated/schemas'
import {
  OptionI,
  getExternalAccountOptions,
} from '@/utils/forms/optionBuilders'

import {
  getExternalAccount,
  getOrganizationId,
  shouldHandleExternalAccountField,
} from '../utils/utils'

type SetValueFnType = (
  name: string,
  value: unknown,
  config?: Partial<{
    shouldValidate: boolean
    shouldDirty: boolean
  }>
) => void

interface UseEditExternalAccountIProps {
  streamingType: OptionI<StreamingType>
  externalAccountId: OptionI
  setValue: SetValueFnType
  initialExternalAccountId: string
}

export const useEditExternalAccount = ({
  streamingType,
  externalAccountId,
  setValue,
  initialExternalAccountId,
}: UseEditExternalAccountIProps) => {
  const [organizationId, setOrganizationId] = useState(null)

  const shouldShowOrganizationId = externalAccountId?.value
  const shouldShowExternalAccount = shouldHandleExternalAccountField(
    streamingType?.value
  )

  const { data, loading: isExternalAccountLoading } = useExternalAccountsQuery({
    fetchPolicy: 'network-only',
    skip: !shouldShowExternalAccount,
  })

  const externalAccountOptions = getExternalAccountOptions(data)

  useEffect(() => {
    if (data && !isExternalAccountLoading) {
      setValue(
        'externalAccountId',
        getExternalAccount(externalAccountOptions, initialExternalAccountId)
      )
      setOrganizationId(getOrganizationId(data, externalAccountId))
    }
  }, [isExternalAccountLoading, streamingType?.value])

  useEffect(() => {
    setOrganizationId(getOrganizationId(data, externalAccountId))
  }, [externalAccountId?.value])

  return {
    externalAccountOptions,
    shouldShowExternalAccount,
    shouldShowOrganizationId,
    organizationId,
  }
}
