import { useState } from 'react'

import { useCreateFloor } from '../hooks/useCreateFloor'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tooltip,
} from '@chakra-ui/react'

import { Floor } from '@/components/icons'
import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import { FormFileUploadControl } from '@/components/ui'
import { StyledFormInputControl } from '@/components/ui'
import { CreateFloorInput } from '@/graphql/generated/schemas'

import { FloorFormInputsIProps } from '../types/types'
import {
  getErrorMessage,
  getSuccessMessage,
  isValidFileType,
} from '../utils/utils'

interface CreateFloorModalIProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateFloorModal = ({
  isOpen,
  onClose,
}: CreateFloorModalIProps) => {
  const { shouldEnableCreateFloor } = usePermissions()
  const [isLoading, setIsLoading] = useState(false)
  const { showSuccess, showError } = useToasts()
  const { facilityOptions, create } = useCreateFloor()
  const { trigger, watch, handleSubmit, register, control, errors } =
    useForm<FloorFormInputsIProps>({
      reValidateMode: 'onChange',
    })
  const fileUpload = watch('file')

  const onSubmit: SubmitHandler<FloorFormInputsIProps> = async (values) => {
    setIsLoading(true)
    const file = values.file.item(0)
    const input: CreateFloorInput = {
      name: values.name,
      facilityId: values.facility?.value,
    }

    try {
      await create(input, file)
      showSuccess(getSuccessMessage(values.name, 'create', file))
      onClose()
    } catch (e) {
      showError(getErrorMessage(values.name, 'create', e))
    }
    setIsLoading(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box align-items='center' display='flex' m='20px auto'>
            <Box display='grid' placeItems='center'>
              <Icon as={Floor} boxSize='26px' strokeColor='#000' />
            </Box>
            <Box
              color='#353849'
              fontSize='26px'
              fontWeight='extrabold'
              letterSpacing='-0.4px'
              ml={3}
            >
              Create Floor
            </Box>
            <Spacer />
            <ModalCloseButton color='#000' opacity={0.4} position='initial' />
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          <Box px={6} py={2}>
            <Box mb={3}>
              <FormInputControl
                dataTestId='createFloor_modal_floorName'
                errorMessage={errors.name && errors.name.message}
                id='name'
                inputRef={register({
                  required: 'Floor name is required',
                })}
                isDisabled={isLoading}
                isInvalid={!!errors.name}
                label='Floor Name'
                placeholder='Enter floor name'
              />
            </Box>
            <Box mb={3}>
              <FormInputSelectControl
                control={control}
                dataTestId='createFloor_modal_facility'
                defaultValue={null}
                id='facility'
                isClearable
                isDisabled={isLoading}
                isInvalid={!!errors.facility}
                isSearchable
                label='Facility'
                options={facilityOptions}
                placeholder='Add a facility where this floor belongs to'
                rules={{ required: true }}
              />
            </Box>
            <StyledFormInputControl mb={3}>
              <Tooltip
                label='Supported file types include PNG'
                shouldWrapChildren
              >
                <FormFileUploadControl
                  accept='.png'
                  dataTestId='createFloor_modal_floorplan'
                  errorMessage={
                    fileUpload &&
                    !isValidFileType(fileUpload) &&
                    'File should be in .png format'
                  }
                  id='file'
                  inputRef={register({ required: false })}
                  isDisabled={isLoading}
                  isInvalid={!isValidFileType(fileUpload)}
                  label='Floor Plan'
                  onFileRemoved={() => trigger(['file'])}
                  placeholder='Attach Floor Plan'
                />
              </Tooltip>
            </StyledFormInputControl>
          </Box>
        </ModalBody>
        <ModalFooter pb={6} py={4}>
          <Button mr={3} onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            isDisabled={
              !isValidFileType(fileUpload) || !shouldEnableCreateFloor
            }
            isLoading={isLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Uploading Floor'
            onClick={handleSubmit(onSubmit)}
          >
            Create Floor
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
