import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'

import { useCreateIncidentMutation } from '@/graphql/generated/hooks'
import { IncidentStatus, IncidentType } from '@/graphql/generated/schemas'

import { getRefetchQueries } from '../utils/refetchQueries'
import { FormInputsIProps } from './../types/types'
import { IncidentModalBody } from './IncidentModalBody'
import { IncidentModalFooter } from './IncidentModalFooter'
import { IncidentModalHeader } from './IncidentModalHeader'

interface CreateIncidentModalIProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateIncidentModal = ({
  isOpen,
  onClose,
}: CreateIncidentModalIProps) => {
  const { shouldEnableCreateIncident } = usePermissions()
  const [createIncident, { loading: isLoading }] = useCreateIncidentMutation()

  const form = useForm<FormInputsIProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const { showSuccess, showError } = useToasts()

  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    const address = values?.address?.value ? [values?.address?.value] : null
    const latitude = values?.latitude
      ? Number(values?.latitude)
      : values.address?.latitude ?? null
    const longitude = values?.longitude
      ? Number(values?.longitude)
      : values.address?.longitude ?? null
    const incidentCategoryId = values?.incidentCategory?.value

    // DeviceHealth incidents are the only incident category that have specified device,
    // so the type can be inferred based on the value of deviceOrigin form field
    const incidentType =
      values.deviceOrigin === undefined ? null : IncidentType.DeviceHealth

    const data = {
      name: values.name,
      type: incidentType,
      facilityId: values.facilityOrigin?.value,
      floorId: values.floorOrigin?.value,
      deviceIds: [values.deviceOrigin?.value],
      status: IncidentStatus.Active,
      description: values.description,
      manualIncidentCategoryId: incidentCategoryId,
      address,
      latitude,
      longitude,
    }

    try {
      const refetchQueries = getRefetchQueries(values.floorOrigin?.value)
      const dataResponse = await createIncident({
        variables: {
          input: data,
        },
        refetchQueries,
      })
      mixpanel.track('Incident Created', {
        incident_id: dataResponse?.data.createIncident.incident.id,
        incident_status: data.status,
        incident_source: values?.deviceOrigin?.label,
        device_id: values?.deviceOrigin?.value,
        device_type: values?.type?.label,
      })
      onClose()
      showSuccess('Incident created.')
    } catch {
      showError()
    }
  }

  return (
    <Modal
      id='createIncident_modal'
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <IncidentModalHeader title='Create Incident' />
        <IncidentModalBody form={form} />
        <IncidentModalFooter
          disabled={!shouldEnableCreateIncident}
          isLoading={isLoading}
          onClick={form?.handleSubmit(onSubmit)}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  )
}
