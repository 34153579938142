import { Scalars } from '@/graphql/generated/schemas'

export type ComponentStatusChange = {
  __typename?: 'ComponentStatusChange'
  edgeDeviceName: Scalars['String']
  componentName: Scalars['String']
  componentHealthStatus: ComponentHealthStatus
  lastSeen: Scalars['String']
}

export enum ComponentHealthStatus {
  RUNNING = 'RUNNING',
  ERRORED = 'ERRORED',
  FINISHED = 'FINISHED',
  BROKEN = 'BROKEN',
}
