import { useMemo } from 'react'

import {
  useDeviceEditQuery,
  useFloorsQuery,
  useUpdateDeviceMutation,
  useUpdateDoorMutation,
} from '@/graphql/generated/hooks'

import { UpdateDeviceInputsI } from '../../types/types'
import { getFloorOptions } from '../../utils/utils'

export const useEditDoor = (deviceId: string) => {
  const { data: floorsData, loading: isFloorsLoading } = useFloorsQuery({
    fetchPolicy: 'network-only',
  })
  const {
    data: deviceData,
    loading: isDeviceLoading,
    error: deviceError,
  } = useDeviceEditQuery({
    variables: {
      id: deviceId,
    },
    fetchPolicy: 'network-only',
  })
  const [updateDevice, { loading: isUpdateDeviceLoading }] =
    useUpdateDeviceMutation()

  const [updateDoorDevice, { loading: isUpdateDoorLoading }] =
    useUpdateDoorMutation()

  const floorsOptions = useMemo(() => getFloorOptions(floorsData), [floorsData])
  const update = async (formData: UpdateDeviceInputsI) => {
    try {
      await updateDevice({
        variables: {
          input: formData,
        },
        refetchQueries: ['Devices'],
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  const updateDoor = async (
    formData: UpdateDeviceInputsI,
    externalId: string
  ) => {
    try {
      await update(formData)
      await updateDoorDevice({
        variables: {
          input: { id: formData?.id, externalId: externalId },
        },
        refetchQueries: ['Devices'],
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  return {
    floorsOptions,
    deviceData,
    deviceError,
    isLoading: isDeviceLoading || isFloorsLoading,
    isUpdateDeviceLoading,
    isUpdateDoorLoading,
    update,
    updateDoor,
  }
}
