import { useEffect } from 'react'

import { usePollComponentStatus } from '../hooks/usePollComponentStatus'
import { useRestartGateway } from '../hooks/useRestartGateway'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { FaPowerOff } from 'react-icons/fa'
import { MdOutlineRouter } from 'react-icons/md'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
  SkeletonModalBody,
} from '@/components/ui'
import { OptionI } from '@/utils/forms/optionBuilders'

import { GatewayComponentStatus } from '../gateway-status'
import {
  ALLOWED_RESTART_COMPONENT_OPTIONS,
  RestartGatewayFormInputsIProps,
} from '../utils/utils'
import { ConfirmGatewayRestartDialog } from './ConfirmGatewayRestartDialog'

interface GatewayDetailsModalIProps {
  id: string
  isOpen: boolean
  onClose: () => void
}

export const GatewayDetailsModal = ({
  id,
  isOpen,
  onClose,
}: GatewayDetailsModalIProps) => {
  const { showSuccess, showError } = useToasts()
  const { shouldEnableRestartGateway } = usePermissions()

  const { deviceData, devices, isDeviceLoading, restart, isRestartLoading } =
    useRestartGateway(id)
  const {
    handleSubmit,
    register,
    control,
    errors,
    setValue,
    formState: { isValid },
    trigger,
  } = useForm<RestartGatewayFormInputsIProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const selectedComponent: OptionI = useWatch({
    name: 'component',
    control: control,
  })

  const {
    isOpen: isConfirmOpen,
    onClose: onConfirmClose,
    onOpen: onConfirmOpen,
  } = useDisclosure()

  const {
    componentStatusData,
    componentStatusError,
    isComponentStatusLoading,
  } = usePollComponentStatus({
    shouldSkip: !selectedComponent,
    componentName: selectedComponent?.value,
    edgeDeviceName: deviceData?.edgeDevice?.name,
  })

  const onSubmit: SubmitHandler<RestartGatewayFormInputsIProps> = async (
    values
  ) => {
    try {
      await restart(deviceData?.edgeDevice?.name, values.component.value)
      showSuccess('Successfully requested Gateway restart')
    } catch (error) {
      showError(error)
    } finally {
      onConfirmClose()
    }
  }

  useEffect(() => {
    if (!isDeviceLoading && devices && deviceData?.edgeDevice?.name) {
      setValue('devices', devices)
      setValue('name', deviceData?.edgeDevice?.name)
      trigger(['name', 'devices'])
    }
  }, [isDeviceLoading, devices, deviceData])

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box align-items='center' display='flex' m='20px auto'>
            <Box display='grid' placeItems='center'>
              <MdOutlineRouter size='32px' />
            </Box>
            <Box
              color='#353849'
              fontSize='26px'
              fontWeight='extrabold'
              letterSpacing='-0.4px'
              ml={2}
            >
              Gateway Details
            </Box>
            <Spacer />
            <ModalCloseButton color='#000' opacity={0.4} position='initial' />
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          {!devices || isDeviceLoading ? (
            <SkeletonModalBody />
          ) : (
            <Box px={6} py={2}>
              <Box mb={3}>
                <FormInputControl
                  data-testid='GatewayDetailsModal:name'
                  errorMessage={errors.name?.message}
                  id='name'
                  inputRef={register({
                    required: 'Gateway name is required',
                  })}
                  isDisabled={true}
                  label='Gateway Name'
                  placeholder='Enter a name for this gateway'
                />
              </Box>
              <Box mb={3}>
                <FormInputSelectControl
                  closeMenuOnSelect={false}
                  control={control}
                  data-testid='GatewayDetailsModal:devices'
                  id='devices'
                  isClearable
                  isDisabled
                  isMulti
                  isSearchable
                  label='Devices'
                  placeholder='No associated devices'
                />
              </Box>
              {shouldEnableRestartGateway && (
                <>
                  <Box mb={3}>
                    <FormInputSelectControl
                      closeMenuOnSelect={true}
                      control={control}
                      data-testid='GatewayDetailsModal:component'
                      defaultValue={null}
                      id='component'
                      isSearchable
                      label='Component'
                      options={ALLOWED_RESTART_COMPONENT_OPTIONS}
                      placeholder='Select component to restart'
                      rules={{
                        required: true,
                      }}
                    />
                  </Box>
                  {selectedComponent && (
                    <Box mt='15px'>
                      <GatewayComponentStatus
                        componentStatusData={componentStatusData}
                        hasError={componentStatusError !== undefined}
                        loading={isComponentStatusLoading}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
        </ModalBody>
        <ModalFooter pb={6} py={4}>
          <Button mr={3} onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          {shouldEnableRestartGateway && (
            <Button
              data-testid='GatewayDetailsModal:save'
              isDisabled={isDeviceLoading || !isValid}
              isLoading={isRestartLoading}
              leftIcon={<FaPowerOff size='16px' />}
              loadingText='Restarting Gateway'
              onClick={onConfirmOpen}
              variant='danger'
            >
              Restart Gateway
            </Button>
          )}
          {isConfirmOpen && (
            <ConfirmGatewayRestartDialog
              componentName={selectedComponent?.value}
              gatewayName={deviceData?.edgeDevice?.name}
              handleOnClick={handleSubmit(onSubmit)}
              isOpen={isConfirmOpen}
              onClose={onConfirmClose}
            />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
