import { useEffect } from 'react'

import { useToasts } from '@/hooks/useToasts'

import {
  ComponentHealthStatus,
  GetComponentStatusChangeQuery,
  useGetComponentStatusChangeQuery,
} from '@/graphql/appsync-component-health'

const COMPONENT_STATUS_POLL_INTERVAL = 5000

interface UsePollComponentStatusIProps {
  edgeDeviceName: string
  componentName: string
  shouldSkip: boolean
}

export const usePollComponentStatus = ({
  edgeDeviceName,
  componentName,
  shouldSkip,
}: UsePollComponentStatusIProps) => {
  const { showSuccess, showError } = useToasts()

  const {
    data: componentStatusData,
    loading: isComponentStatusLoading,
    error: componentStatusError,
    previousData: previousComponentStatusData,
  } = useGetComponentStatusChangeQuery({
    variables: {
      edgeDeviceName,
      componentName,
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    pollInterval: COMPONENT_STATUS_POLL_INTERVAL,
    skip: shouldSkip,
  })

  useEffect(() => {
    if (
      hasComponentStatusDataUpdated(
        previousComponentStatusData,
        componentStatusData
      )
    ) {
      const updateMessage = getComponentStatusUpdateMessage(componentStatusData)
      if (
        componentStatusData?.getComponentStatusChange?.componentHealthStatus ===
        ComponentHealthStatus.RUNNING
      ) {
        showSuccess(updateMessage)
      } else {
        showError(updateMessage)
      }
    }
  }, [componentStatusData])

  return {
    componentStatusData,
    componentStatusError,
    isComponentStatusLoading,
  }
}

/**
 * Determines whether newly polled component status data reflect updated status.
 *
 * It should return false when:
 *  - new data corresponds to a different component
 *  - new data is retrieved for the first time (previous data is undefined)
 *  - new data last seen value has not changed
 */
export const hasComponentStatusDataUpdated = (
  previous: GetComponentStatusChangeQuery,
  current: GetComponentStatusChangeQuery
): boolean => {
  if (previous === undefined || current === undefined) {
    // First retrieval, does not indicate status change
    return false
  }

  const previousComponent = previous?.getComponentStatusChange?.componentName
  const previousLastSeen = previous?.getComponentStatusChange?.lastSeen
  const currentComponent = current?.getComponentStatusChange?.componentName
  const currentLastSeen = current?.getComponentStatusChange?.lastSeen

  if (
    [
      previousComponent,
      previousLastSeen,
      currentComponent,
      currentLastSeen,
    ].some((e) => e === undefined)
  ) {
    // Missing data, something went wrong
    return false
  }

  if (
    previousComponent === currentComponent &&
    previousLastSeen !== currentLastSeen
  ) {
    // New last seen value for the same component, status updated
    return true
  }

  // No change in the status
  return false
}

const getComponentStatusUpdateMessage = (
  data: GetComponentStatusChangeQuery
) => {
  const { componentName, edgeDeviceName, componentHealthStatus } =
    data?.getComponentStatusChange
  return `Component ${componentName} on ${edgeDeviceName} gateway ${getComponentHealthStatusMessage(
    componentHealthStatus
  )}.`
}

const getComponentHealthStatusMessage = (status: ComponentHealthStatus) => {
  switch (status) {
    case ComponentHealthStatus.BROKEN:
      return 'is broken'
    case ComponentHealthStatus.ERRORED:
      return 'has error'
    case ComponentHealthStatus.FINISHED:
      return 'was stopped'
    case ComponentHealthStatus.RUNNING:
      return 'is running'
  }
}
