import { IconType } from 'react-icons'
import { BiLock } from 'react-icons/bi'

import { Box, Circle, Grid } from '@chakra-ui/react'

interface CreateDropdownItemIProps {
  Icon: IconType
  label: string
  active: boolean
  onClick?: () => void
  disabled?: boolean
  dataTestId?: string
}

export const CreateDropdownItem = ({
  Icon,
  label,
  active,
  onClick,
  disabled,
  dataTestId,
}: CreateDropdownItemIProps) => {
  return (
    <Grid
      _hover={{
        bgColor: active ? '#9FB8E5' : '#EDF2F7',
      }}
      alignItems='center'
      as='button'
      bgColor={disabled ? '' : active ? '#9FB8E5' : ''}
      borderRadius='lg'
      cursor={disabled ? 'not-allowed' : 'pointer'}
      data-testid={dataTestId}
      gap='4'
      onClick={disabled ? null : onClick}
      p='4'
      templateColumns='38px 1fr auto'
      transition='ease-in-out'
      transitionDuration='300ms'
      transitionProperty='background-color'
      width='100%'
    >
      <Circle bgColor='#3279C7' opacity={disabled ? '0.4' : '1'} size='38px'>
        <Icon color='white' size={24} />
      </Circle>
      <Box
        color={active ? '#fff' : '#2D2E41'}
        fontWeight='bold'
        letterSpacing='-0.53px'
        lineHeight='20px'
        opacity={disabled ? '0.4' : '1'}
        textAlign='left'
        transition='ease-in-out'
        transitionDuration='300ms'
        transitionProperty='color'
      >
        {label}
      </Box>
      {disabled && <BiLock color='#2D2E41' size='24px' />}
    </Grid>
  )
}
