import { Box, Skeleton } from '@chakra-ui/react'

export const EditFloorFormSkeleton = () => {
  return (
    <Box mb={3} px={6} py={2}>
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
    </Box>
  )
}
