import { BiDoorOpen, BiVideo } from 'react-icons/bi'

import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'

import { DeviceType } from '@/graphql/generated/schemas'

interface DeviceI {
  macAddress: string
  type: string
  manufacturerName: string
  modelName: string
}

interface IProps {
  devices: DeviceI[] | unknown[]
  handleOnSelect?: (d: {
    id: number
    type: string
    make: string
    model: string
    deviceId: string
  }) => void
  selectedDeviceId?: string
}

const Sensor = ({ sensor }: { sensor?: string }) => {
  return (
    <Flex alignItems='center' justifyContent='center'>
      {sensor === DeviceType.Door ? (
        <BiDoorOpen size={22} />
      ) : (
        <BiVideo size={20} />
      )}
    </Flex>
  )
}

const TableHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <Th
      borderColor='#eaedf3'
      fontSize='15px'
      fontWeight='medium'
      letterSpacing='-0.4px'
      textAlign='center'
      textTransform='capitalize'
    >
      {children}
    </Th>
  )
}

const TableData = ({ children }: { children: React.ReactNode }) => {
  return (
    <Td
      borderColor='#eaedf3'
      color='#2D2E41'
      fontSize='14px'
      fontWeight='bold'
      h='full'
      letterSpacing='-0.53px'
      textAlign='center'
    >
      {children}
    </Td>
  )
}

export const DeviceTable = ({ devices }: IProps) => {
  return devices.length > 0 ? (
    <Table size='sm'>
      <Thead>
        <Tr>
          <TableHeader>Type</TableHeader>
          <TableHeader>Make</TableHeader>
          <TableHeader>Model</TableHeader>
          <TableHeader>Device ID / MAC ID</TableHeader>
        </Tr>
      </Thead>
      <Tbody>
        {devices.map((d: DeviceI, i: number) => (
          <Tr
            bg='white'
            cursor='pointer'
            h='38px'
            key={i}
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='background-color'
          >
            <TableData>
              <Sensor sensor={d.type} />
            </TableData>
            <TableData>{d.manufacturerName}</TableData>
            <TableData>{d.modelName}</TableData>
            <TableData>{d.macAddress}</TableData>
          </Tr>
        ))}
      </Tbody>
    </Table>
  ) : (
    <Box
      bg='#ecf0fa'
      borderColor='#eaedf3'
      color='#2D2E41'
      fontSize='14px'
      fontWeight='bold'
      letterSpacing='-0.53px'
      p='2'
      textAlign='center'
    >
      No results
    </Box>
  )
}
