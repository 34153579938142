import { ApolloError } from '@apollo/client'

const ERROR_IS_PLACED = 'device.can.not.be.deleted.is.placed'
const ERROR_HAS_INCIDENTS = 'device.can.not.be.deleted.has.incidents'
const ERROR_HAS_ASSOCIATION = 'device.can.not.be.deleted.has.association'

export const getErrorMessage = (e: ApolloError, device: string) => {
  if (
    e?.graphQLErrors?.[0]?.extensions?.code === ERROR_IS_PLACED ||
    e?.graphQLErrors?.[0]?.extensions?.code === ERROR_HAS_INCIDENTS ||
    e?.graphQLErrors?.[0]?.extensions?.code === ERROR_HAS_ASSOCIATION
  ) {
    return `Error: ${e.message}`
  } else {
    return `Error deleting ${device}.`
  }
}
