import { useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { CreateModalType } from '../types/types'

interface UseCreateModalReturnIProps {
  openedModal: CreateModalType | null
  isOpen: boolean
  openModal: (createOption: CreateModalType) => void
  closeModal: () => void
}

export const useCreateModal = (): UseCreateModalReturnIProps => {
  const [openedModal, setOpenedModal] = useState<null | CreateModalType>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const openModal = (createOption: CreateModalType): void => {
    setOpenedModal(createOption)
    onOpen()
  }

  const closeModal = (): void => {
    onClose()
    setOpenedModal(null)
  }

  return { openedModal, isOpen, openModal, closeModal }
}
