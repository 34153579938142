import { HiOutlineExclamation } from 'react-icons/hi'

import { Box } from '@chakra-ui/react'

import { PinMarker, StaticMapBox } from '@/components/ui'

export const StaticMap = ({
  latitude,
  longitude,
}: {
  latitude?: number | string
  longitude?: number | string
}) => {
  if (latitude === undefined || longitude === undefined) {
    return null
  }

  return (
    <Box mb='16px'>
      <StaticMapBox
        height='150px'
        latitude={Number(latitude)}
        longitude={Number(longitude)}
      >
        <PinMarker
          Icon={HiOutlineExclamation}
          borderColor='rgba(208, 16, 48, 1)'
          fadedPulseBoxShadow='rgba(0, 0, 0, 0)'
          pulseBoxShadow='rgba(208, 16, 48, 0.4)'
        />
      </StaticMapBox>
    </Box>
  )
}
