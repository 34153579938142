import React from 'react'

import { RiShieldFlashLine } from 'react-icons/ri'

import { Box, Grid } from '@chakra-ui/react'

interface EditCameraModalHeaderIProps {
  title: string
  subTitle: string
}

export const EditCameraModalHeader = ({
  title,
  subTitle,
}: EditCameraModalHeaderIProps) => {
  return (
    <Box px='10' py='6'>
      <Grid gap='10px' gridTemplateColumns='30px 1fr'>
        <RiShieldFlashLine size='30px' />
        <Box>
          <Box
            color='#353849'
            fontSize='26px'
            fontWeight='extrabold'
            letterSpacing='-0.4px'
            lineHeight='1'
            mb='3'
          >
            {title}
          </Box>
          <Box color='#2D2E41' fontSize='17px' letterSpacing='-0.4px' mt='3'>
            {subTitle}
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}
