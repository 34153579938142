import { Box, Button } from '@chakra-ui/react'

const ShowCoordinates = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Box color='#8E919F' fontWeight='medium' mb='3'>
      No address? You can also{' '}
      <Button onClick={onClick} variant='link'>
        add geo-coordinates
      </Button>
    </Box>
  )
}

export { ShowCoordinates }
