import { ManualIncidentCategoriesQuery } from '@/graphql/generated/operations'
import { getIncidentCategoryOptions } from '@/utils/forms/optionBuilders'
import { sortOptions } from '@/utils/forms/optionHelpers'

export const isDeviceHealthSelected = (
  incidentCategoriesData: ManualIncidentCategoriesQuery,
  incidentCategoryId: string
): boolean => {
  const incidentCategories = sortOptions(
    getIncidentCategoryOptions(incidentCategoriesData)
  )
  return (
    incidentCategories?.find((i) => i.value === incidentCategoryId)?.label ===
    'Device Health'
  )
}
