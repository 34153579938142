import { useEffect, useState } from 'react'

import { UseFormMethods } from 'react-hook-form'

import { Box, FormLabel, HStack } from '@chakra-ui/react'

import { AddressSearch, FormInputControl } from '@/components/ui'
import { StyledFormInputControl } from '@/components/ui'

import { FormInputsIProps } from '../types/types'
import { validateLatitude, validateLongitude } from '../utils/validation'
import { ShowAddress } from './ShowAddress'
import { ShowCoordinates } from './ShowCoordinates'
import { StaticMap } from './StaticMap'

interface MarketIncidentFormIProps {
  form: UseFormMethods<FormInputsIProps>
}

export const MarketIncidentForm = ({ form }: MarketIncidentFormIProps) => {
  const [shouldShowAddress, setShowAddress] = useState(true)
  const {
    register,
    unregister,
    setValue,
    trigger,
    watch,
    errors,
    formState: { isSubmitting },
  } = form

  useEffect(() => {
    register('address', { required: true })
  }, [])

  const toggleShowAddress = () => {
    if (shouldShowAddress) {
      setValue('address', null)
      unregister('address')
    } else {
      setValue('latitude', null)
      setValue('longitude', null)
      register('address', { required: true })
    }
    setShowAddress((prevShowAddress) => !prevShowAddress)
  }

  const watchCoordinates = watch(['latitude', 'longitude'])
  const watchAddress = watch('address')

  const shouldShowAddressMap = watchAddress?.value
  const shouldShowCoordinatesMap =
    watchCoordinates?.latitude &&
    watchCoordinates?.longitude &&
    !errors.longitude &&
    !errors.latitude

  return (
    <Box p={0}>
      {shouldShowAddress ? (
        <>
          <StyledFormInputControl mb='4'>
            <FormLabel>Address</FormLabel>
            <AddressSearch
              defaultValue={null}
              isSubmitting={isSubmitting}
              onSelectAddress={(address) => {
                setValue('address', address)
                trigger(['address'])
              }}
            />
          </StyledFormInputControl>
          {shouldShowAddressMap && (
            <StaticMap
              latitude={watchAddress?.latitude}
              longitude={watchAddress?.longitude}
            />
          )}
          <ShowCoordinates onClick={toggleShowAddress} />
        </>
      ) : (
        <>
          <HStack align='stretch' mb='4' spacing='3'>
            <FormInputControl
              errorMessage={errors.latitude && errors.latitude.message}
              id='latitude'
              inputRef={register({ validate: validateLatitude })}
              isInvalid={Boolean(errors.latitude)}
              label='Latitude'
              placeholder='Latitude'
            />
            <FormInputControl
              errorMessage={errors.longitude && errors.longitude.message}
              id='longitude'
              inputRef={register({ validate: validateLongitude })}
              isInvalid={Boolean(errors.longitude)}
              label='Longitude'
              placeholder='Longitude'
            />
          </HStack>
          {shouldShowCoordinatesMap && (
            <StaticMap
              latitude={watchCoordinates?.latitude}
              longitude={watchCoordinates?.longitude}
            />
          )}
          <ShowAddress onClick={toggleShowAddress} />
        </>
      )}
    </Box>
  )
}
