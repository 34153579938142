import { latRegex, lonRegex } from '../types/types'

export const validateLongitude = (value: string) => {
  if (!value) {
    return 'Incident longitude is required'
  } else if (!lonRegex.test(value)) {
    return 'Enter a valid incident longitude'
  } else return true
}

export const validateLatitude = (value: string) => {
  if (!value) {
    return 'Incident latitude is required'
  } else if (!latRegex.test(value)) {
    return 'Enter a valid incident latitude'
  } else return true
}
