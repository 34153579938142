import { HiExclamation } from 'react-icons/hi'

import { AlertDialogBody, AlertDialogFooter, Box, Flex } from '@chakra-ui/react'

import { BasicAlertDialog, Button } from '@/components/ui'

interface ConfirmGatewayRestartDialogIProps {
  isOpen: boolean
  onClose: () => void
  handleOnClick: () => void
  gatewayName?: string
  componentName?: string
}

export const ConfirmGatewayRestartDialog = ({
  isOpen,
  onClose,
  handleOnClick,
  gatewayName,
  componentName,
}: ConfirmGatewayRestartDialogIProps) => {
  return (
    <BasicAlertDialog
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      title='Restarting Gateway'
    >
      <AlertDialogBody>
        <Box letterSpacing='-0.53px'>
          This will restart {componentName || 'the selected component'} on the{' '}
          {gatewayName || 'target'} edge device.
        </Box>
        <Flex alignItems='center' color='#D11D3A' flexDirection='row' pt='4'>
          <HiExclamation size='24px' />
          <Box
            fontSize='16px'
            fontWeight='medium'
            letterSpacing='-0.53px'
            ml='1'
          >
            <Box as='span' fontWeight='bold'>
              Warning:
            </Box>{' '}
            This action cannot be undone!
          </Box>
        </Flex>
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button mr='3' onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button onClick={handleOnClick} variant='danger'>
          Confirm Restart
        </Button>
      </AlertDialogFooter>
    </BasicAlertDialog>
  )
}
