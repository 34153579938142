import React, { useEffect, useState } from 'react'

import { useCreateDevices } from '../hooks/useCreateDevices'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'
import { RiShieldFlashLine } from 'react-icons/ri'

import {
  Box,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tooltip,
} from '@chakra-ui/react'

import { Button, FormInputSelectControl } from '@/components/ui'
import { FormFileUploadControl } from '@/components/ui'
import { StyledFormInputControl } from '@/components/ui'
import { DeviceType } from '@/graphql/generated/schemas'

import {
  CreationToastIProps,
  FormInputsIProps,
  defaultToast,
} from '../types/types'
import { getErrorMessage, isValidFileType } from '../utils/utils'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateDeviceModal = ({ isOpen, onClose }: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { shouldEnableCreateDevice } = usePermissions()
  const [toastMessage, setToastMessage] =
    useState<CreationToastIProps>(defaultToast)
  const { handleSubmit, watch, trigger, errors, register, control, formState } =
    useForm({
      mode: 'onChange',
    })
  const fileUpload = watch('file')
  const { isValid } = formState
  const { showSuccess, showError } = useToasts({
    toastDuration: toastMessage?.duration,
  })
  const { create, deviceTypes } = useCreateDevices()

  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    const file = values.file.item(0)
    const type = values.type.value
    try {
      setIsLoading(true)
      const resp = await create(type, file)
      const count = resp?.data?.createDevices?.length
      const deviceType = type === DeviceType.Camera ? 'cameras' : 'doors'
      setToastMessage({
        message: `Successfully created ${count} ${deviceType}`,
        duration: 4000,
        isSuccess: true,
      })
      setIsLoading(false)
      onClose()
    } catch (e) {
      setToastMessage({
        message: getErrorMessage(e),
        duration: 6000,
        isSuccess: false,
      })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (toastMessage?.message) {
      if (toastMessage.isSuccess) {
        showSuccess(toastMessage.message)
      } else {
        showError(toastMessage.message)
      }
      setToastMessage(defaultToast)
    }
  }, [toastMessage])

  return (
    <Modal
      id='createDeviceModal'
      isCentered
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      scrollBehavior='outside'
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalCloseButton color='#000' opacity={0.4} />

        <ModalBody p='0'>
          <Box px='10' py='6'>
            <Grid gap='10px' gridTemplateColumns='30px 1fr'>
              <RiShieldFlashLine size='30px' />
              <Box>
                <Box
                  color='#353849'
                  fontSize='26px'
                  fontWeight='extrabold'
                  letterSpacing='-0.4px'
                  lineHeight='1'
                  mb='3'
                >
                  Create Device
                </Box>
                <Box
                  color='#2D2E41'
                  fontSize='17px'
                  letterSpacing='-0.4px'
                  mt='3'
                >
                  Bulk Upload
                </Box>
              </Box>
            </Grid>
          </Box>
          <form>
            <Box px='6'>
              <Box mb='3'>
                <FormInputSelectControl
                  control={control}
                  errorMessage={!!errors?.type && 'Select a device type'}
                  id='type'
                  isClearable
                  isInvalid={!!errors?.type}
                  label='Device Type'
                  options={deviceTypes}
                  placeholder='Device Type Selection'
                  rules={{ required: true }}
                />
              </Box>

              <StyledFormInputControl mb={3}>
                <Tooltip
                  label='Supported file types include CSV'
                  shouldWrapChildren
                >
                  <FormFileUploadControl
                    accept='.csv'
                    dataTestId='createFloor_modal_floorplan'
                    errorMessage={
                      fileUpload &&
                      !isValidFileType(fileUpload) &&
                      'File should be in .csv format'
                    }
                    id='file'
                    inputRef={register({ required: true })}
                    isInvalid={!isValidFileType(fileUpload)}
                    label='Devices'
                    onFileRemoved={() => trigger(['file'])}
                    placeholder='Attach CSV'
                  />
                </Tooltip>
              </StyledFormInputControl>
            </Box>
          </form>
        </ModalBody>
        <ModalFooter py='4'>
          <Button mr='3' onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            isDisabled={!isValid || !shouldEnableCreateDevice}
            isLoading={isLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Creating Devices'
            onClick={handleSubmit(onSubmit)}
          >
            Create Device
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
