import { useMemo } from 'react'

import {
  useCreateEdgeDeviceMutation,
  useDevicesUnpairedQuery,
} from '@/graphql/generated/hooks'
import { CreateEdgeDeviceInput } from '@/graphql/generated/schemas'

import { getDeviceOptions } from '../utils/utils'

export const useCreateGateway = () => {
  const { data } = useDevicesUnpairedQuery({
    fetchPolicy: 'cache-and-network',
  })
  const [createEdgeDevice, { loading: isCreateLoading }] =
    useCreateEdgeDeviceMutation()

  const devicesOptions = useMemo(() => getDeviceOptions(data), [data])

  const create = async (formData: CreateEdgeDeviceInput) => {
    await createEdgeDevice({
      variables: {
        input: formData,
      },
      refetchQueries: ['GatewayDevices'],
    })
  }

  return {
    devicesOptions,
    create,
    isCreateLoading,
  }
}
