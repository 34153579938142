import { FloorsQuery } from '@/graphql/generated/operations'

import { Options } from '../types/types'

export const getFloorOptions = (floorsData: FloorsQuery): Options =>
  floorsData?.floors?.edges
    ?.sort((a, b) =>
      a?.node?.facility?.name.localeCompare(b?.node?.facility?.name)
    )
    ?.map((f) => ({
      label: `${f?.node?.name} - (${f?.node?.facility?.name})`,
      value: f?.node?.id,
    }))

export const isPlaced = (device: { x?: number; y?: number }): boolean =>
  device.x != null && device.y != null
