import { useEffect } from 'react'

import { useEditGateway } from '../hooks/useEditGateway'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'
import { MdOutlineRouter } from 'react-icons/md'
import { useSelector } from 'react-redux'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
  SkeletonModalBody,
} from '@/components/ui'
import { UpdateEdgeDeviceInput } from '@/graphql/generated/schemas'
import { selectEditMode } from '@/redux/ui/uiSlice'

import { GatewayFormInputsIProps } from '../utils/utils'

interface EditGatewayModalIProps {
  id: string
  isOpen: boolean
  onClose: () => void
}

export const EditGatewayModal = ({
  id,
  isOpen,
  onClose,
}: EditGatewayModalIProps) => {
  const { shouldEnableUpdateGateway } = usePermissions()
  const isEditMode = useSelector(selectEditMode)

  const { showSuccess, showError } = useToasts()
  const {
    deviceData,
    devicesOptions,
    devices,
    update,
    isUpdateLoading,
    isDeviceLoading,
  } = useEditGateway(id)

  const { handleSubmit, register, control, errors, setValue } =
    useForm<GatewayFormInputsIProps>({
      reValidateMode: 'onChange',
    })
  const onSubmit: SubmitHandler<GatewayFormInputsIProps> = async (values) => {
    const name = values.name
    const deviceIds = values.devices?.map((d) => d.value) || []
    const formData: UpdateEdgeDeviceInput = { id, name, deviceIds }

    try {
      await update(formData)
      showSuccess('Successfully updated Gateway')
      onClose()
    } catch (error) {
      showError(error)
    }
  }

  useEffect(() => {
    if (!isDeviceLoading && devices) {
      setValue('devices', devices, {
        shouldValidate: true,
      })
      setValue('name', deviceData?.edgeDevice?.name)
    }
  }, [isDeviceLoading, devices])

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box align-items='center' display='flex' m='20px auto'>
            <Box display='grid' placeItems='center'>
              <MdOutlineRouter size='32px' />
            </Box>
            <Box
              color='#353849'
              fontSize='26px'
              fontWeight='extrabold'
              letterSpacing='-0.4px'
              ml={2}
            >
              Edit Gateway
            </Box>
            <Spacer />
            <ModalCloseButton color='#000' opacity={0.4} position='initial' />
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          {!devices || isDeviceLoading ? (
            <SkeletonModalBody />
          ) : (
            <Box px={6} py={2}>
              <Box mb={3}>
                <FormInputControl
                  data-testid='EditGatewayModal:name'
                  errorMessage={errors.name?.message}
                  id='name'
                  inputRef={register({
                    required: 'Gateway name is required',
                  })}
                  isDisabled={isUpdateLoading || !isEditMode}
                  label='Gateway Name'
                  placeholder='Enter a name for this gateway'
                />
              </Box>
              <Box mb={3}>
                <FormInputSelectControl
                  closeMenuOnSelect={false}
                  control={control}
                  data-testid='EditGatewayModal:devices'
                  id='devices'
                  inputRef={register()}
                  isClearable
                  isDisabled={isUpdateLoading || !isEditMode}
                  isMulti
                  isSearchable
                  label='Devices'
                  options={devicesOptions}
                  placeholder='Add devices'
                />
              </Box>
            </Box>
          )}
        </ModalBody>
        <ModalFooter pb={6} py={4}>
          <Button mr={3} onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            data-testid='EditGatewayModal:save'
            isDisabled={
              !shouldEnableUpdateGateway || isUpdateLoading || !isEditMode
            }
            isLoading={isUpdateLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Saving'
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
