import { ApolloError } from '@apollo/client'

const PNG_MIME_TYPE = 'image/png'

export const isValidFileType = (fl: FileList): boolean => {
  if (fl?.length === 0) {
    return true
  }
  return fl?.[0]?.type === PNG_MIME_TYPE
}

export const removeExtension = (filename: string) => {
  return filename?.substring(0, filename?.lastIndexOf('.')) || filename
}

export const getErrorMessage = (
  floorName: string,
  operation: 'create' | 'update',
  e: ApolloError
) => {
  const operationString = operation === 'create' ? 'creating' : 'updating'
  if (e?.graphQLErrors?.[0]?.extensions?.code === 'floor.name.not.unique') {
    return `Error: ${e.message}`
  } else {
    return e?.message ?? `Error ${operationString} Floor ${floorName}`
  }
}

export const getSuccessMessage = (
  floorName: string,
  operation: 'create' | 'update',
  file?: File
) => {
  const operationString = operation === 'create' ? 'created' : 'updated'
  return file
    ? `Successfully ${operationString} Floor ${floorName} with Floor Plan`
    : `Successfully ${operationString} Floor ${floorName}`
}
