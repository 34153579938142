import {
  useDevicesQuery,
  useFacilityFloorsQuery,
  useFacilityTypesQuery,
  useManualIncidentCategoriesQuery,
} from '@/graphql/generated/hooks'
import { DeviceType } from '@/graphql/generated/schemas'

export const useIncidentCreationData = (
  facilityId?: string,
  floorId?: string
) => {
  const hasFacilityId = Boolean(facilityId)
  const hasFloorId = Boolean(floorId)

  const { data: facilitiesData } = useFacilityTypesQuery({
    fetchPolicy: 'network-only',
    skip: hasFacilityId || hasFloorId,
  })
  const { data: floorsData } = useFacilityFloorsQuery({
    variables: {
      id: facilityId,
    },
    fetchPolicy: 'network-only',
    skip: !hasFacilityId,
  })
  const { data: devicesData } = useDevicesQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        facilityIdIn: [facilityId],
        floorIdIn: [floorId],
        typeNot: DeviceType.Checkpoint,
      },
    },
    skip: !hasFacilityId || !hasFloorId,
  })
  const { data: categoriesData } = useManualIncidentCategoriesQuery({
    nextFetchPolicy: 'network-only',
    errorPolicy: 'all',
  })

  return {
    facilitiesData,
    floorsData,
    devicesData,
    categoriesData,
  }
}
