import { MAX_CSV_SIZE, MAX_FILENAME_LENGTH } from '@/hooks/useUpload'
import { useUpload } from '@/hooks/useUpload/useUpload'

import { useCreateDevicesMutation } from '@/graphql/generated/hooks'
import { DeviceType, UploadedFileType } from '@/graphql/generated/schemas'

import { Options } from '../../types/types'

const deviceTypes: Options = [
  { label: 'Camera', value: DeviceType.Camera },
  { label: 'Door', value: DeviceType.Door },
]

export const useCreateDevices = () => {
  const [upload] = useUpload()
  const [createDevices] = useCreateDevicesMutation()

  const create = async (type: DeviceType, file: File) => {
    const fileId = await upload({
      file,
      fileType: UploadedFileType.CsvFile,
      validationOptions: {
        maxFileSizeInBytes: MAX_CSV_SIZE,
        maxFileNameLength: MAX_FILENAME_LENGTH,
      },
    })

    return await createDevices({
      variables: {
        input: {
          deviceType: type,
          sheetExternalId: fileId,
        },
      },
      refetchQueries: [
        'Devices',
        'FloorDevicesCount',
        'FacilityDevicesByFloors',
      ],
    })
  }

  return {
    deviceTypes,
    create,
  }
}
