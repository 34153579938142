import { useToasts } from '@/hooks/useToasts'

import { useDeleteDeviceMutation } from '@/graphql/generated/hooks'

import { getErrorMessage } from '../utils/utils'

interface DeleteDeviceIProps {
  deviceId: string
  device: string
  onClose: () => void
}

export const useDeleteDevice = ({
  deviceId,
  device,
  onClose,
}: DeleteDeviceIProps) => {
  const { showSuccess, showError } = useToasts({ toastDuration: 3000 })
  const [deleteCheckpointDevice, { loading: isLoading }] =
    useDeleteDeviceMutation()

  const deleteDevice = async () => {
    try {
      await deleteCheckpointDevice({
        variables: {
          input: {
            id: deviceId,
          },
        },
        refetchQueries: ['Devices'],
      })
      showSuccess(`Successfully deleted ${device}.`)
      onClose()
    } catch (error) {
      showError(getErrorMessage(error, device))
    }
  }

  return {
    deleteDevice,
    isLoading,
  }
}
