import {
  DevicesUnpairedQuery,
  EdgeDeviceQuery,
} from '@/graphql/generated/operations'

interface OptionI {
  label: string
  value: string
}

type Options = Array<OptionI>

export interface GatewayFormInputsIProps {
  name: string
  devices?: Options
}

export interface RestartGatewayFormInputsIProps {
  name: string
  devices?: Options
  component: OptionI
}

export const getDeviceOptions = (
  devicesData: DevicesUnpairedQuery
): Options => {
  const edges = devicesData?.devices?.edges
  if (!edges) return []
  return (
    [...edges]
      ?.sort((a, b) =>
        a?.node?.facility?.name.localeCompare(b?.node?.facility?.name)
      )
      .map((f) => ({
        label: `${f?.node?.name} - ${f?.node?.floor.name} - ${f?.node?.facility?.name}`,
        value: f?.node?.id,
      })) || []
  )
}

export const getEdgeDeviceOptions = (edgeData: EdgeDeviceQuery): Options => {
  const edges = edgeData?.edgeDevice?.devices?.edges
  if (!edges) return []
  return (
    [...edges]
      ?.sort((a, b) =>
        a?.node?.facility?.name.localeCompare(b?.node?.facility?.name)
      )
      .filter((f) => !f?.node?.archived)
      .map((f) => ({
        label: `${f?.node?.name} - ${f?.node?.floor.name} - ${f?.node?.facility?.name}`,
        value: f?.node?.id,
      })) || []
  )
}

export const ALLOWED_RESTART_COMPONENT_OPTIONS = [
  'com.hivewatch.VideoStreamer',
  'com.hivewatch.VideoRecorder',
  'com.hivewatch.PacsPipeline',
].map((c) => ({ value: c, label: c }))
