import { useMemo } from 'react'

import {
  useEdgeDeviceQuery,
  useRestartEdgeDeviceMutation,
} from '@/graphql/generated/hooks'

import { getEdgeDeviceOptions } from '../utils/utils'

export const useRestartGateway = (id: string) => {
  const { data: deviceData, loading: isDeviceLoading } = useEdgeDeviceQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id },
  })

  const [restartEdgeDevice, { loading: isRestartLoading }] =
    useRestartEdgeDeviceMutation()

  const devices = useMemo(() => getEdgeDeviceOptions(deviceData), [deviceData])

  const restart = async (edgeDeviceName: string, componentName: string) => {
    const response = await restartEdgeDevice({
      variables: {
        input: {
          edgeDeviceName: edgeDeviceName,
          componentName: componentName,
        },
      },
    })
    if (response?.data?.restartEdgeDevice?.isRestartRequested === false) {
      throw new Error('Cannot request Gateway restart')
    }
  }

  return {
    deviceData,
    devices,
    isDeviceLoading,
    isRestartLoading,
    restart,
  }
}
