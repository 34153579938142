import { QueryHookOptions, QueryResult, gql, useQuery } from '@apollo/client'

import { GraphqlClientType } from '@/config/apollo'
import { Maybe } from '@/graphql/generated/schemas'

import { ComponentStatusChange } from '../types'

export type GetComponentStatusChangeQuery = {
  __typename?: 'Query'
  getComponentStatusChange?: Maybe<ComponentStatusChange>
}

export type GetComponentStatusChangeQueryVariables = {
  edgeDeviceName: string
  componentName: string
}

export const GET_COMPONENT_STATUS_CHANGE = gql`
  query GetComponentStatusChange(
    $edgeDeviceName: String!
    $componentName: String!
  ) {
    getComponentStatusChange(
      edgeDeviceName: $edgeDeviceName
      componentName: $componentName
    ) {
      edgeDeviceName
      componentName
      componentHealthStatus
      lastSeen
    }
  }
`

export const useGetComponentStatusChangeQuery = (
  options?: QueryHookOptions<
    GetComponentStatusChangeQuery,
    GetComponentStatusChangeQueryVariables
  >
): QueryResult<GetComponentStatusChangeQuery> => {
  return useQuery(GET_COMPONENT_STATUS_CHANGE, {
    ...options,
    context: { target: GraphqlClientType.APPSYNC_COMPONENT_HEALTH },
  })
}
