import { HiOutlineExclamation } from 'react-icons/hi'

import { Box, ModalCloseButton, ModalHeader } from '@chakra-ui/react'

interface IncidentModalHeaderIProps {
  title: string
}

export const IncidentModalHeader = ({ title }: IncidentModalHeaderIProps) => {
  return (
    <ModalHeader>
      <Box alignItems='center' display='flex'>
        <HiOutlineExclamation size='30px' />
        <Box ml={3}>
          <Box
            color='#353849'
            fontSize='26px'
            fontWeight='extrabold'
            letterSpacing='-0.4px'
            lineHeight='1'
          >
            {title}
          </Box>
        </Box>
      </Box>
      <ModalCloseButton color='#000' opacity={0.4} />
    </ModalHeader>
  )
}
