import { Grid, GridItem, Skeleton, Text } from '@chakra-ui/react'

export const GatewayComponentStatusSkeleton = () => (
  <Grid
    alignContent='center'
    alignItems='center'
    color='#2D2E41'
    data-testId='GatewayComponentStatusSkeleton:skeleton'
    fontSize='14px'
    gap='5px 2px'
    templateColumns='1fr 160px 90px'
    textAlign='left'
  >
    <GridItem>
      <Text fontWeight='semibold'>Component</Text>
    </GridItem>
    <GridItem>
      <Text fontWeight='semibold'>Last Seen</Text>
    </GridItem>
    <GridItem>
      <Text fontWeight='semibold'>Status</Text>
    </GridItem>
    <GridItem>
      <Skeleton h={4} w='250px' />
    </GridItem>
    <GridItem>
      <Skeleton h={4} w='150px' />
    </GridItem>
    <GridItem>
      <Skeleton h={4} w='80px' />
    </GridItem>
  </Grid>
)
