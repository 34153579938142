import { DeviceType } from '@/graphql/generated/schemas'

export interface OptionI {
  label: string
  value: DeviceType
}

export interface FormInputsIProps {
  type: OptionI
  file: FileList
}

export interface CreationToastIProps {
  message: string
  duration: number
  isSuccess: boolean
}

export const defaultToast: CreationToastIProps = {
  message: null,
  duration: 4000,
  isSuccess: null,
}
