import React, { useEffect } from 'react'

import { useEditCamera } from '../hooks/useEditCamera'
import { useLiveStreamSetup } from '@/hooks/useLiveStreamSetup'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
  SkeletonModalBody,
} from '@/components/ui'

import { DeviceTable } from '../../components/DeviceTable'
import { CameraFormDataTypeI } from '../../types/types'
import { isPlaced } from '../../utils/utils'
import { getCameraInputs, validateMac } from '../utils/utils'
import { EditCameraLiveStreamingFields } from './EditCameraLiveStreamingFields'
import { EditCameraModalHeader } from './EditCameraModalHeader'

interface EditDoorModalIProps {
  isOpen: boolean
  onClose: () => void
  deviceId: string
}

export const EditCameraModal = ({
  isOpen,
  onClose,
  deviceId,
}: EditDoorModalIProps) => {
  const form = useForm<CameraFormDataTypeI>({
    mode: 'onChange',
  })
  const {
    handleSubmit,
    errors,
    register,
    control,
    setValue,
    formState: { dirtyFields },
  } = form

  const { isOrangePeel } = useLiveStreamSetup()
  const { shouldEnableUpdateDevice } = usePermissions()
  const {
    handleSetLiveStreamFields,
    shouldShowLiveStreamFields,
    cameraData,
    cameraError,
    isLoading,
    floorsOptions,
    isUpdateDeviceLoading,
    isUpdateCameraLoading,
    updateCamera,
  } = useEditCamera(deviceId)
  const { showSuccess, showError } = useToasts()

  const onSubmit: SubmitHandler<CameraFormDataTypeI> = async (values) => {
    const formData = {
      id: deviceId,
      name: values.name,
      floorId: values.location?.value,
      macAddress: values.macAddress?.length > 0 ? values.macAddress : null,
    }
    const cameraData = getCameraInputs(deviceId, values)
    try {
      await updateCamera(formData, cameraData, dirtyFields)
      showSuccess('Camera updated.')
      onClose()
    } catch (e) {
      showError()
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setValue('name', cameraData?.device?.name, { shouldValidate: true })
      setValue(
        'location',
        floorsOptions?.find((i) => i.value === cameraData?.device?.floor?.id),
        {
          shouldValidate: true,
        }
      )
      setValue('macAddress', cameraData?.device?.macAddress)
      setValue('externalId', cameraData?.device?.camera?.externalId)
    }
  }, [isLoading, floorsOptions])

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      scrollBehavior='inside'
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <EditCameraModalHeader
          subTitle={
            cameraError
              ? ' This device is not found.'
              : 'Enter Device Info to connect to a Device'
          }
          title='Edit Camera'
        />
        <ModalCloseButton color='#000' opacity={0.4} />
        {cameraError ? (
          <>
            <ModalBody p='0' />
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody p='0'>
              {!cameraData || isLoading ? (
                <SkeletonModalBody />
              ) : (
                <>
                  <form>
                    <Box px='6'>
                      <Box mb='3'>
                        <FormInputControl
                          data-testid='devicesPage_editCameraModal_name'
                          errorMessage={errors.name && errors.name.message}
                          id='name'
                          inputRef={register({
                            required: 'Device Name is required',
                          })}
                          isInvalid={!!errors.name}
                          label='Device Name'
                          placeholder='Enter the Device Name'
                        />
                      </Box>
                      <Box mb='3'>
                        <FormInputSelectControl
                          control={control}
                          data-testid='devicesPage_editCameraModal_location'
                          defaultValue={null}
                          errorMessage='Select a device location'
                          id='location'
                          isClearable
                          isDisabled={isLoading || isPlaced(cameraData?.device)}
                          isInvalid={!!errors?.location}
                          label='Device Location'
                          options={floorsOptions}
                          placeholder='Select Device Location'
                          rules={{ required: true }}
                          tooltipText={
                            isPlaced(cameraData?.device) &&
                            'Device must not be placed to update device location'
                          }
                        />
                      </Box>
                      <Box mb='3'>
                        <FormInputControl
                          data-testid='devicesPage_editCameraModal_macAddress'
                          errorMessage={
                            errors.macAddress && errors.macAddress.message
                          }
                          id='macAddress'
                          inputRef={register({
                            validate: (v) => validateMac(v),
                            required: false,
                          })}
                          isInvalid={!!errors?.macAddress}
                          label='Enter MAC Address'
                          placeholder='Enter MAC Address'
                        />
                      </Box>

                      <Box mb='3'>
                        <FormInputControl
                          data-testid='devicesPage_editCameraModal_externalId'
                          errorMessage={errors.externalId?.message}
                          id='externalId'
                          inputRef={register()}
                          label='External ID'
                          placeholder='Enter External ID'
                        />
                      </Box>
                      {shouldShowLiveStreamFields && (
                        <EditCameraLiveStreamingFields
                          cameraData={cameraData}
                          form={form}
                          isLoading={isLoading}
                        />
                      )}
                      {!shouldShowLiveStreamFields && isOrangePeel && (
                        <Box display='flex' justifyContent='center' p='20px'>
                          <Button onClick={handleSetLiveStreamFields}>
                            Set Live Streaming
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </form>
                  <DeviceTable devices={[cameraData?.device as unknown]} />
                </>
              )}
            </ModalBody>
            <ModalFooter py='4'>
              <Button mr='3' onClick={onClose} variant='secondary'>
                Cancel
              </Button>
              <Button
                isDisabled={!shouldEnableUpdateDevice}
                isLoading={isUpdateDeviceLoading || isUpdateCameraLoading}
                leftIcon={<HiOutlineCheckCircle size='24px' />}
                loadingText='Saving Changes'
                onClick={handleSubmit(onSubmit)}
              >
                Save Changes
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
