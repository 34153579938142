import { HiPlus } from 'react-icons/hi'

import { Box } from '@chakra-ui/react'
import { Menu } from '@headlessui/react'
import { motion } from 'framer-motion'

import { Button } from '@/components/ui'

import { CreateModalOptions, CreateModalType } from '../types/types'
import { CreateDropdownItem } from './CreateDropdownItem'

interface CreateButtonDropdownIProps {
  openModal: (modalType: CreateModalType) => void
  createModalOptions: CreateModalOptions
}

// memo is used to avoid unnecessary rerendering resulting in sending multiple
// MixPanel events for single button press
export const CreateButtonDropdown = ({
  openModal,
  createModalOptions,
}: CreateButtonDropdownIProps) => {
  return (
    <Menu as={Box} pos='relative' pr='6'>
      {({ open }) => (
        <>
          <Menu.Button
            _focus={{
              outline: 'none',
            }}
            as={Button}
            data-testid='createButtonDropdown_button'
            rightIcon={<HiPlus size={24} />}
            variant='success'
          >
            Create
          </Menu.Button>
          {open && (
            <Menu.Items
              animate={{ height: 'auto', opacity: 1 }}
              as={motion.div}
              data-testid='createButtonDropdown_menu'
              initial={{ height: 0, opacity: 0 }}
              static
              transition={{ duration: 0.15 }}
            >
              <Box
                _focus={{
                  outline: 'none',
                }}
                bgColor='white'
                border='1px solid #D5DCE4'
                borderRadius='lg'
                boxShadow='xl'
                pos='absolute'
                right='10px'
                top='calc(100% + 6px)'
                w='430px'
                zIndex='dropdown'
              >
                <Box m={2}>
                  {createModalOptions
                    .filter(({ hidden }) => !hidden)
                    .map(({ key: modalType, label, icon, disabled }) => (
                      <Menu.Item key={modalType}>
                        {({ active }) => (
                          <CreateDropdownItem
                            Icon={icon}
                            active={active}
                            dataTestId={`createButtonDropdown_menuItem_${modalType}`}
                            disabled={disabled}
                            label={label}
                            onClick={() => {
                              openModal(modalType)
                            }}
                          />
                        )}
                      </Menu.Item>
                    ))}
                </Box>
              </Box>
            </Menu.Items>
          )}
        </>
      )}
    </Menu>
  )
}
