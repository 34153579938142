import { useEffect } from 'react'

import { useEditFloor } from '../hooks/useEditFloor'
import { useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tooltip,
} from '@chakra-ui/react'

import { Floor } from '@/components/icons'
import {
  Button,
  FormFileUploadControl,
  FormInputControl,
  FormInputSelectControl,
  StyledFormInputControl,
} from '@/components/ui'
import { getFacilityOption } from '@/utils/forms/optionBuilders'

import { FloorFormInputsIProps } from '../types/types'
import { isValidFileType } from '../utils/utils'
import { EditFloorFormSkeleton } from './EditFloorFormSkeleton'
import { FloorPlanRemove } from './FloorPlanRemove'

interface EditFloorModalIProps {
  floorId: string
  isOpen: boolean
  onClose: () => void
}

export const EditFloorModal = ({
  floorId,
  isOpen,
  onClose,
}: EditFloorModalIProps) => {
  const {
    floorDetails,
    isFloorDetailsLoading,
    isUpdateLoading,
    onDeleteFloorPlan,
    onUpdateFloor,
  } = useEditFloor({ floorId, onClose })

  const {
    trigger,
    watch,
    handleSubmit,
    register,
    control,
    errors,
    setValue,
    formState,
  } = useForm<FloorFormInputsIProps>({
    reValidateMode: 'onChange',
  })
  const fileUploadWatch = watch('file')

  useEffect(() => {
    if (floorDetails) {
      setValue('name', floorDetails?.floor?.name)
      setValue('facility', getFacilityOption(floorDetails?.floor?.facility))
    }
  }, [isFloorDetailsLoading])

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box align-items='center' display='flex' m='20px auto'>
            <Box display='grid' placeItems='center'>
              <Icon as={Floor} boxSize='26px' strokeColor='#000' />
            </Box>
            <Box
              color='#353849'
              fontSize='26px'
              fontWeight='extrabold'
              letterSpacing='-0.4px'
              ml={3}
            >
              Edit Floor
            </Box>
            <Spacer />
            <ModalCloseButton color='#000' opacity={0.4} position='initial' />
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          {isFloorDetailsLoading ? (
            <EditFloorFormSkeleton />
          ) : (
            <Box px={6} py={2}>
              <Box mb={3}>
                <FormInputControl
                  dataTestId='editFloor_modal_floorName'
                  errorMessage={errors.name && errors.name.message}
                  id='name'
                  inputRef={register({
                    required: 'Floor name is required',
                  })}
                  isDisabled={isFloorDetailsLoading}
                  isInvalid={!!errors.name}
                  label='Floor Name'
                  placeholder='Enter floor name'
                />
              </Box>
              <Box mb={3}>
                <FormInputSelectControl
                  control={control}
                  dataTestId='editFloor_modal_facility'
                  defaultValue={getFacilityOption(
                    floorDetails?.floor?.facility
                  )}
                  id='facility'
                  isDisabled={true}
                  label='Facility'
                  placeholder='Add a facility where this floor belongs to'
                />
              </Box>
              <StyledFormInputControl mb={3}>
                {floorDetails?.floor?.floorPlan ? (
                  <FloorPlanRemove
                    floorPlanName={floorDetails.floor.floorPlan.name}
                    isUpdateLoading={isUpdateLoading}
                    onRemove={onDeleteFloorPlan}
                  />
                ) : (
                  <Tooltip
                    label='Supported file types include PNG'
                    shouldWrapChildren
                  >
                    <FormFileUploadControl
                      accept='.png'
                      dataTestId='editFloor_modal_floorplan'
                      errorMessage={
                        fileUploadWatch &&
                        !isValidFileType(fileUploadWatch) &&
                        'File should be in .png format'
                      }
                      id='file'
                      inputRef={register({ required: false })}
                      isDisabled={isUpdateLoading}
                      isInvalid={!isValidFileType(fileUploadWatch)}
                      label='Floor Plan'
                      onFileRemoved={() => trigger(['file'])}
                      placeholder='Attach Floor Plan'
                    />
                  </Tooltip>
                )}
              </StyledFormInputControl>
            </Box>
          )}
        </ModalBody>
        <ModalFooter pb={6} py={4}>
          <Button mr={3} onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            isDisabled={
              !formState.isDirty &&
              (!isValidFileType(fileUploadWatch) || isFloorDetailsLoading)
            }
            isLoading={isUpdateLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Saving Changes'
            onClick={handleSubmit(onUpdateFloor)}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
