import { HiOutlineTrash } from 'react-icons/hi'

import {
  Box,
  Center,
  Flex,
  FormLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { ConfirmRemovalDialog } from '@/components/ui'

interface FloorPlanRemoveIProps {
  floorPlanName?: string
  isUpdateLoading?: boolean
  onRemove?: () => void
}

export const FloorPlanRemove = ({
  floorPlanName,
  onRemove,
  isUpdateLoading,
}: FloorPlanRemoveIProps) => {
  const {
    isOpen: isConfirmRemovalOpen,
    onOpen: onConfirmRemovalOpen,
    onClose: onConfirmRemovalClose,
  } = useDisclosure()

  return (
    <>
      <FormLabel>Floor plan</FormLabel>
      <Flex alignItems='center' height='48px' justifyContent='space-between'>
        <Box
          border='2px solid rgb(159, 184, 229)'
          borderRadius='6px'
          flex='1'
          height='48px'
          mr={2}
        >
          <Flex alignItems='center' height='100%'>
            <Text
              color='rgb(153, 153, 153)'
              fontSize='14px'
              fontWeight='600'
              ml={4}
              noOfLines={1}
            >
              {floorPlanName}
              .png
            </Text>
          </Flex>
        </Box>
        <Center
          _hover={{
            bgColor: '#b40e2a',
          }}
          bgColor='#D01030'
          boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
          cursor='pointer'
          data-testid='EditFloorModal:removeFloorButton'
          h='full'
          minW='48px'
          onClick={onConfirmRemovalOpen}
          role='button'
          rounded='5px'
          transition='ease-in-out'
          transitionDuration='300ms'
          transitionProperty='background-color'
          w='48px'
        >
          <HiOutlineTrash color='#fff' size='21px' />
        </Center>
      </Flex>
      {isConfirmRemovalOpen && (
        <ConfirmRemovalDialog
          handleOnClick={onRemove}
          isLoading={isUpdateLoading}
          isOpen={isConfirmRemovalOpen}
          itemTitle='this Floorplan'
          onClose={onConfirmRemovalClose}
          title='Deleting Floorplan'
        />
      )}
    </>
  )
}
